<template>
  <div class="m-2">
    <b-row class="mb-3">
      <b-button variant="light" class="p-50 shadow-sm border position-absolute top-0 end-0 right-0 m-2" @click="fecharModal">
        <FeatherIcon icon="XIcon" />
      </b-button>
    </b-row>
    <b-row class="mb-1 text-center">
      <h1 class="mb-0 w-100">{{ tiposStatusGenerico[itemParaModal.statusGenerico] }}</h1>
    </b-row>

    <b-row class="justify-content-center mb-2">
      <h3 class="mb-1">{{ itemParaModal.retorno.statusIntegracao }}</h3>
      <p v-if="!erroRetorno">{{ itemParaModal.retorno.statusDetalhado }}</p>
      <div v-else>
        <h5>Lista de erros:</h5>
        <b-alert show variant="danger" v-for="(erro, index) in erroRetorno" :key="index" class="d-flex p-50">
          <FeatherIcon icon="AlertCircleIcon" class="mr-1" size="22" />
          <p>
            {{ erro }}
          </p>
        </b-alert>
      </div>
    </b-row>
    <b-row>
      <b-col cols="12" v-for="(item, index) in itemParaModal.retorno.anexos" :key="index">
        <b-card class="border mb-1">
          <b-row>
            <b-col cols="10" class="d-flex align-items-center justify-content-start">
              <FeatherIcon icon="FileTextIcon" class="mr-1" size="22" />
              <p class="mb-0">{{ item }}</p>
            </b-col>
            <b-col cols="2" class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" class="w-auto" @click="anexarDocumento">Anexar</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row md="12">
      <b-col cols="12" class="d-flex justify-content-center">
        <b-form-group label-for="textarea" label="Adicionar nota" class="w-100">
          <b-form-input id="tituloNota" size="md" v-model="nota.tituloNota" class="mb-1" placeholder="Titulo da nota..." />
          <b-form-textarea
            id="descricaoNota"
            size="md"
            v-model="nota.descricaoNota"
            class="mb-1"
            placeholder="Descrição da nota..."
          />
          <b-button variant="primary" class="w-100" v-if="!isBusy" @click="adicionarNota">Adicionar Nota</b-button>
          <b-button variant="primary" class="w-100" v-else><b-spinner small /></b-button>
        </b-form-group>
      </b-col>
      <b-col cols="12" class="d-flex mx-auto justify-content-between pointer mt-1" v-if="notasLinhaDoTempo.length > 0">
        <div class="toggle-list w-100">
          <b-input-group class="toggle-header mb-1 d-flex align-items-center justify-content-between" @click="toggleList">
            <div class="d-flex align-items-center justify-content-start">
              <b-input-group-prepend class="mr-50">
                <b-icon variant="outline-secondary" @click="toggleList" :icon="isOpen ? 'chevron-down' : 'chevron-right'" />
              </b-input-group-prepend>
              <h5 class="text-notas mb-0">Notas adicionadas</h5>
            </div>
            <div class="d-flex align-items-center justify-content-end">
              <b-badge variant="info" pill class="note-count">{{ notasLinhaDoTempo.length }}</b-badge>
            </div>
          </b-input-group>

          <b-collapse v-model="isOpen">
            <b-list-group class="note-list">
              <b-list-group-item
                v-for="(note, index) in notasLinhaDoTempo"
                :key="index"
                class="d-flex justify-content-between align-items-start note-item"
              >
                <b-row>
                  <b-col cols="12">
                    <hr class="m-0 mb-1" />
                  </b-col>
                  <b-col cols="12" class="d-flex justify-content-between mb-1">
                    <h5 class="mb-0 text-center align-self-center">{{ note.tituloNota }}</h5>
                  </b-col>
                  <b-col cols="12" class="d-flex flex-column">
                    <p class="text-wrap">{{ note.descricaoNota }}</p>
                    <small class="text-muted">Criado em: {{ formatarDataCurto(note.createdAt) }}</small>
                    <small class="text-muted">Por: {{ note.user }}</small>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { formatarDataCurto } from '@/utils/Functions/Formatacao/Formatacao'
  import useJwt from '@/auth/jwt/useJwt'
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

  export default {
    components: {
      formatarDataCurto,
      useJwt,
    },
    props: {
      itemParaModal: {
        type: Object,
        required: true,
      },
      tiposStatusGenerico: {
        type: Object,
      },
    },

    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarDataCurto,
        text: '',
        isOpen: false,
        notasLinhaDoTempo: [],
        nota: {
          tituloNota: '',
          descricaoNota: '',
        },
        isBusy: false,
        erroRetorno: '',
      }
    },
    async mounted() {
      await this.buscarNotas()
      await this.ajustarRetornoErro()
    },
    methods: {
      fecharModal() {
        this.$emit('fecharModal')
      },
      toggleList() {
        this.isOpen = !this.isOpen
      },

      ajustarRetornoErro() {
        if (this.itemParaModal.retorno.statusDetalhado.startsWith('Erro: ')) {
          this.erroRetorno = this.itemParaModal.retorno.statusDetalhado
            .replace('Erro: ', '') // Removendo o prefixo "Erro: "
            .split(',') // Dividindo cada erro separado por vírgula
            .map((erro) => erro.trim()) // Removendo espaços em branco
        }
      },

      adicionarNota() {
        this.isBusy = true
        const dadosNota = {
          descricaoNota: this.nota.descricaoNota,
          tituloNota: this.nota.tituloNota,
          linhaDoTempoId: this.itemParaModal.id,
          userId: this.userData.id,
        }
        useJwt
          .post('linhaTempoContrato/postTimeLineNotes', dadosNota)
          .then(async (response) => {
            this.$message.success('Nota adicionada com sucesso!')
            await this.buscarNotas()
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
            this.nota.descricaoNota = ''
            this.nota.tituloNota = ''
          })
      },
      buscarNotas() {
        useJwt
          .get(`linhaTempoContrato/getTimeLineNotes/${this.itemParaModal.id}`)
          .then((response) => {
            this.notasLinhaDoTempo = response.data
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>
<style scoped>
  .toggle-list .toggle-header {
    cursor: pointer;
    align-items: center;
  }
  .text-notas {
    width: auto;
    flex-grow: 1;
  }
  .note-count {
    margin-left: 10px;
    font-weight: bold;
  }
  .note-item {
    background-color: transparent !important; /* Remove o hover */
    border: none;
    max-width: 100%; /* Limita a largura do item */
    overflow-wrap: break-word; /* Quebra palavras longas */
    white-space: normal; /* Evita texto de ficar em uma única linha */
    display: flex;
    flex-direction: column;
  }
  .note-list {
    max-height: 30vh; /* Defina a altura máxima desejada */
    overflow-y: auto; /* Adiciona o scroll vertical */
    overflow-x: hidden; /* Remove o scroll horizontal */
    padding-right: 0.5rem;
  }
</style>
